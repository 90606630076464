@import '../styles/config';
@import '../styles/colors';
@import '../styles/mixins';

.App.poi-detail{
  .site-content{
    figure.wp-block-embed-youtube{
      display: flex;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;
      @media(max-width: $breakpoint-md){
        flex-wrap: wrap;
      }
      > * {
        flex-grow: 1;
        flex-shrink: 0;
        width: 50%;
        @media(max-width: $breakpoint-md){
          width: 100%;
        }
      }
      .wp-block-embed__wrapper{
        display: flex;
        iframe{
           width: 100%;
        }
      }
      figcaption{
        background-color: $armyGreen;
        color: $popColor;
        font-weight: 800;
        font-size: 1.8em;
        padding: 50px 90px;
        @media(max-width: $breakpoint-md){
          padding-right: 40px;
          padding-left: 40px;
        }
      }
      @media(min-width: 1100px) {
        margin-left: calc(-100vw / 2 + 1100px / 2);
        margin-right: calc(-100vw / 2 + 1100px / 2);
        width: auto;
      }
    }
  }
}

@media(min-width: $breakpoint-md){
  // open sidebar 
  .main-page.calling,
  .App .main-page.social,
  .App .main-page.thanks{
    .text-intro{
      display: none;
    }
    .sidebar-wrap{
      width: 100%;
      .call{
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
      }
    }
  }
  .main-page{
    &.calling{
      .sidebar-wrap{
        .script-wrap{
          max-width: 800px;
          margin: 0 auto;
        }
        .action-wrpr{
          max-width: 300px;
          margin: 0 auto;
        }
      }
    }
    .under-sidebar{
      display: none;
    }
  }
  .App .main-page.social,
  .App .main-page.thanks{
    .sidebar-wrap{
      .inner-wrap{
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
      }
      .form-wrap{
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        > * {
          flex: 1;
        }
        padding: 0 0px;
        .btn-wrpr{
          margin-top: 0;
          margin-bottom: 0;
        }
        input{
          margin-bottom: 0;
          margin-right: 30px;
        }
      }

    }
  }
}
