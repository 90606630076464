@import '../styles/config';
@import '../styles/colors';

.page-about,
.news {

    h2 {
      margin-top: 30px;
      font-size: 1.7em;
      a {
        color: $armyGreen;
        text-decoration: none;
        &:hover{
          color: $armyGreen;
        }
      }
    } 

    h3 {
      color: $black;
      font-size: 1.05em;
    }

    p {
      margin-top: 2rem;
      font-size: 1.8rem;
      line-height: 1.5;
    }
    hr{
      margin-top: 40px;
      margin-bottom: 50px;
    }
}
