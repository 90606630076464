@import '../styles/colors';

.sign-count {
  margin: 1rem 0;
  text-align: center;
  color: $whiteText;
  font-style: italic;
  font-size: 1.4rem;
  font-weight: bold;

  span {
    font-size: 1.6rem;
  }
}
