.btn-wrpr {
  color: $whiteText;
  display: flex;
  margin: 2rem 0;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;

  &:active, &:focus {
    outline: none;
  }

  svg {
    width: 24px;
    min-width: 24px;
    margin-right: 1rem;
    align-self: normal;
  }

  span {
    font-size: 1.6rem;
    padding-left: 0;
    font-weight: 600;
  }
}

.tweet-wrap,
.action-wrpr {
  .btn-wrpr {
    background-color: $popColor;
    padding: 1rem;
    cursor: pointer;
    color: $blackText;
    width: 100%;

    svg {
      flex-grow: 1;
      margin: 0;
      text-align: right;
    }

    span {
      text-transform: uppercase;
      flex-grow: 1;
      text-align: left;
    }
    &.twitter-btn-wrpr{
      background-color: #1DA1F2;
      color: #fff;
    }
    &.facebook-btn-wrpr{
      background-color: #3b5998;
      color: #fff;
    }
    &:active,
    &:hover{
      background-color: $popColorAlt;
    }
    &:disabled{
      // background-color: $btnDisabledBg;
      color: $btnDisabledText;
    }
  }
  &.callout{
    .btn-wrpr{
      &.twitter-btn-wrpr{
        background-color: $popColor;
        color: $blackText;
        &:active,
        &:hover{
          background-color: $popColorAlt;
        }
      }
    }
  }
}

input[type=email],
input[type=text]{
  border: none;
  display: block;
  width: 100%;
  background-color: $inputBg;
  color: $formText;
  padding: $pagePad;
  font-size: 1.4rem;
  margin-bottom: 2rem;

  &:active,
  &:focus{
    border: none;
  }

  &::placeholder {
    color: $formText;
    // opacity: 1;
  }

  // browser wants something to go here
  &:focus, &:active {
    outline: $inputFocusOutline;
  }
}

.texture-image{
  background-image: url(../assets/imagetexture-sm.png);
}
.texture-text {
  background-image: url(../assets/texttexture-7.png);
}
.texture-social-image {
  background-image: url(../assets/suit_social.png);
}

h5 a,
a.right-link{
  @extend .a;
  display: block;
  text-align: right;
  font-weight: 700;
  font-size: 1.7rem;
}

@media (max-width: $breakpoint-sm) {
  .tweet-wrap .btn-wrpr span, .action-wrpr .btn-wrpr span{
    font-size: 1.3rem;
  }
}
