@import '../styles/config';
@import '../styles/colors';

#partners {
  background-color: $whiteBg;

  h2{
    color: $blackText;
    padding-bottom: 2 * $pagePad;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    padding: 0;

    li {
      list-style: none;
      margin: 1% 2%;
      width: 46%;
      min-height: 100px;
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
}

@media (min-width: $breakpoint-md) {
  body {
    #partners ul li {
      width: 29.33%;
    }
  }
}
