@import "../styles/config";
@import "../styles/colors";

.ak-form{
  button {
    text-transform: uppercase;
    width: 100%;
    background-color: $popColor;
    color: $blackText;
    font-weight: bold;
    cursor: pointer;

  }
}