@import '../styles/config';
@import '../styles/mixins';

.page-footer {
  text-align: center;

  h2{
    @extend .sans;
    font-weight: 900;
    margin: 2rem 0 2rem;
  }
  h3 {
    font-size: 1.8rem;
    font-weight: 900;
    // @extend .headline-sans;
  }

  .page-footer-actions {
    display: flex;
    flex-wrap: wrap;

    button {
      width: calc(60% - 2rem);
      margin: 1rem auto;
      min-width: 250px;
    }
  }
}

@media (min-width: $breakpoint-lg) {
  body .page-footer {
    .page-footer-actions {
      button {
        width: calc(33.333% - #{4 * $pagePad});
        margin: 3rem #{2 * $pagePad};
        min-width: auto;
      }
    }
  }
}
