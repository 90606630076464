@import '../styles/colors';

#headshot {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: grayscale(100%) contrast(1); 
  transition: transform 0.125s;
  &:before{
    content: '';
    background: url(../imagetexture.png) $imageOverlay top left no-repeat;
    background-size: cover;
    z-index: 2;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
