
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$breakpoint-menu: $breakpoint-md;

$pagePad: 15px;

$verticalSectionPad: 4 * $pagePad;
