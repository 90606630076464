
$armyGreen: #525A10;

$popColor: #cdf900;
$popColorAlt: $armyGreen;

$orangeBg: #FF793A;
$orangeDark: $orangeBg;
$grayBg: #e7e7e7;
$whiteBg: white;

$black: black;

$whiteText: white;
$blackText: $black;
$blueText: $armyGreen;
$formText: #777;
// $imageOverlay: rgba(31, 11, 11, 0.35);
$imageOverlay: transparent;

$inputBg: #eee;
$inputFocusOutline: 1px solid $formText;

$btnDisabledBg: #8AE5E0 ;
$btnDisabledText: #686565;
