@import "reset.css";
@import './styles/colors';
@import './styles/mixins';
@import './styles/config';

*{
  box-sizing: border-box;
}
html {
  font-size: 10px; /* set the base val for 1 rem */
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.7rem;
  @extend .sans;
  line-height: 1.2;
  background-color: $whiteBg;
}
#root{
  overflow-x: hidden;
}
em{
  font-style: italic;
}

.cap-width {
  max-width: 1100px;
  margin: 0 auto;
}

.btn {
  display: block;
  background-color: $popColor;
  padding: 10px 20px;
  border: none;
  border-radius: 2px;
  &:disabled{
    color: $btnDisabledText;
  }
}

.link-btn {
  background-color: transparent;
  border: none;
  padding: 3px 0;
  line-height: 1.5;
  cursor: pointer;
  text-decoration: none;
  color: #523c31;
  text-align: center;
  display: block;
  width: 100%;
  &:hover{
    text-decoration: underline;
  }
}

h1, h2, h3, h4 {
  margin-bottom: 2rem;
}
h2{
  @extend .headline-sans;
  line-height: 1.2;
}

h3 {
  font-size: 3rem;
}

p {
  margin-bottom: 2rem;
}

strong{
  font-weight: 700;
}

.subscribe-btn{
  $h: 50px;
  position: relative;
  margin-top: $h / 2;
  $p: 3px;
  .b-wrap{
    position: absolute;
    z-index: 2;
    right: 0px;
    top: -$h / 2 - $p / 2;
    padding: $p;
    background-color: $black;
    &::before{
      content: ' ';
      position: absolute;
      left: 0px;
      top: 0px;
      height: $h / 2 + $p / 2;
      width: 100%;
      background-color: $whiteBg;
      z-index: 3;
    }
    .btn{
      position: relative;
      text-decoration: none;
      z-index: 5;
      color: $black;
      font-weight: 600;
      font-size: 1.2em;
      padding: 15px 30px;
      height: $h;
      line-height: 1;
      &:hover{
        color: $popColor;
        background-color: $black;
      }
      @media(max-width:$breakpoint-md){
        font-size: 1.1em;
      }
    }
    &:hover{
        background-color: $popColor;
      &::before{
        background-color: $popColor;
      }
    }
  }
  // &::before{
  //   content: '';
  //   position: absolute;
  //   z-index: 1;
  //   top: 3px;
  //   right: 0px;
  //   height: $h / 2;
  //   width: 100%;
  //   background-color: $orangeBg;
  // }
}