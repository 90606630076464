@import './styles/config';
@import './styles/colors';
@import './styles/shared';
@import './styles/mixins';


.App{
  min-width: 300px;
  padding: 0;
  // background-color: $black;
  $sideMargin: 2 * $pagePad;

  // &.no-hs{
  //   background-color: $whiteBg;
  //   nav ul#nav-menu li a {
  //     color: $blackText;
  //   }
  // }

  // elements that need to be placed on top of the headshot elem
  #header, #content, #tabs {
    position: relative;
    z-index: 2;
  }
  #content{
    z-index: 3;
  }
  #header{
    z-index: 4;
  }
  
  h1 {
    @extend .h1;
  }
  h2 {
    font-size: 2.5rem;
    color: $whiteText;
    margin-bottom: 1rem;
    letter-spacing: 0.3px;
  }
  a{
    @extend .a;
  }


  #content {
    // margin: 100px $sideMargin 0;
  }


  #header {
    // position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  .pt{
    padding-top: 60px;
  }

  .post {
    background-color: $grayBg;
    padding: 5rem #{5 * $pagePad};
    margin: 0 #{-5 * $pagePad};
    @include sides($sideMargin);
    line-height: 1.5;

    h2 {
      color: $blackText;
      // margin-bottom: 2rem;
      font-size: 3.1rem;
    }
    h3 {
      color: $blueText;
      font-size: 1.4em;
      a{
        @extend .a;
      }
    }
    h4 {
      color: $blackText;
      font-weight: bold;
    }
    img{
      max-width: 100%;
      display: block;
    }
    .wp-caption,
    .wp-block-image{
      position: relative;
      overflow-y: hidden;
      margin-bottom: 2rem;
      width: calc(100% + #{3 * $sideMargin}) !important;
      padding: 0 0 0 #{2 * $pagePad};
      margin: $verticalSectionPad #{-2 * $sideMargin};
      img{
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        filter: contrast(1.2);
        object-fit: cover;
      }
      .wp-caption-text,
      figcaption{
        @extend .h1;
        padding: 3 * $pagePad #{$sideMargin} #{4 * $pagePad} #{$sideMargin};
        position: relative;
        z-index: 3;
        font-size: 4.5rem;
      }
    }
    .site-content{
      display: block;
      padding-bottom: $verticalSectionPad;
    }
  }
  &.page-about,
  &.page-persons-of-interest,
  &.page-news {
    // @extend .dark-nav;
    .text-intro{
      min-height: auto;
      padding-bottom: 3rem;
    }
    #content {
      > article {
        padding-top: 5rem;
        padding-bottom: 5rem;
        @include sides($sideMargin);
      }
    }
  } 
  &.poi-detail{
    #intro{
      .text-intro{
        min-height: 350px;
        padding-left: 0;
        padding-right: 0;
        h1{
          display: inline;
          line-height: 1.4;
        }
      }
    }
  }
  // &.page-persons-of-interest{
  //   #content {
  //     > article {
  //       @include sides(2.5 * $sideMargin);
  //     }
  //   }
  // }
  .people-wrap{
    background-color: white;
    padding: $verticalSectionPad $sideMargin;
    margin: 0 -$sideMargin;
  }
  .hp-news{
    background-color: $grayBg;
    padding-top: 60px;
    @include sides($sideMargin);
    h3{
      margin-bottom: 1rem;
    }
    .common-safe-span > .wp-caption:first-of-type{
      margin-top: 0;
    }
  }

  .callout {
    margin: 0 #{-$sideMargin};
    padding: 2rem #{3 * $pagePad};
    background: $orangeBg;
  }


  #partners {
    padding: $verticalSectionPad #{$sideMargin} 200px;
    margin: 0 #{-$sideMargin};
  }

  #tabs {
    display: flex;
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0px;
    height: 50px;
    overflow: hidden;
    transform: translateY(100%);
    transition: transform 0.5s;
  }
  #intro{
    padding-bottom: 2rem;
    .text-intro{
      padding-left: 25px;
      padding-right: 25px;
    }
    h2{
      margin-left: -7px;
      font-size: 1.9em;;
      // @extend .sans;
      // font-weight: 900;
      font-weight: normal;
      color: $popColor;
    }
    h1{
      span{
        color: $blackText;
        // @extend .highlighted-text;
      }
    }
  }
  #footer{
    background-color: $black;
    padding: 50px 25px;
    display: flex;;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    .project{
      color: $whiteText;
      flex-shrink: 0;
      font-size: 0.85em;
      display: flex;
      align-items: center;
      p{
        margin-bottom: 0;
      }
      a {
        padding-top: 5px;
        display: inline-block;
        background: url(./assets/rdp.png) right 0px no-repeat;
        background-size: auto 100%;
        padding-right: 65px;
        height: 30px;
        filter: brightness(0) invert(1);
      }
    }
    .socials{
      a{
        font-size: 1.5em;
        display: inline-block;
        padding-right: 10px;
      }
    }
    @media(max-width: $breakpoint-md) {
      flex-direction: column;
      align-items: center;
      .project{
        margin-top: 30px;
        text-align: center;
      }
    }

  }
}


@media (min-width: $breakpoint-md) {
  $multiplier: 3;
  $sideMargin: $multiplier * $pagePad;
  body .App {
    #intro {
      display: flex;

      .text-intro {
        padding-top: 5rem;
        flex-grow: 2;
      }
      .sidebar {
        flex-grow: 1;
      }
    }

    #content {
      margin-top: 0;
    }
    &.page-about,
    &.page-poi,
    &.page-news {
      .callout {
        margin: 0 #{-$sideMargin};
        padding: 2rem #{3 * $pagePad};
      }
      #content {
        margin: 0 #{$sideMargin} 0;
        > article {
          margin: 0 -#{$sideMargin};
          padding: 5rem $sideMargin;
          background-color: $grayBg;
        }
      }
    } 
    .callout {
      margin: 0 #{-$sideMargin};
    }
    #header {
      position: relative;
      top: auto;
      left: auto;
      padding: 0;
    }

    .post {
      margin-top: -50px;
      padding-top: 75px;
      padding-bottom: 0;
      @include sides($sideMargin);
      .wp-caption,
      .wp-block-image{
        width: calc(100% + #{2 * $sideMargin}) !important;
        padding: 0 #{$sideMargin};
        margin: $verticalSectionPad #{-$sideMargin};
        .wp-caption-text,
        figcaption{
          padding: 6 * $pagePad #{5 * $pagePad} #{9 * $pagePad} #{5 * $pagePad};
        }
      }
    }

    .people-wrap{
      padding: $verticalSectionPad $sideMargin;
      margin: 0 #{-$sideMargin};
    }
    .hp-news{
      padding-bottom: $verticalSectionPad;
      @include sides($sideMargin);
    }
    #tabs {
      display: none;
    }
    #partners {
      padding: $verticalSectionPad #{$sideMargin} 200px;
      margin: 0 #{-$sideMargin};
    }
  }
}
@media (max-width: $breakpoint-md) {
  body .App {
    .post{
      .wp-caption,
      .wp-block-image{
        .wp-caption-text,
        figcaption{
          font-size: 3.5rem;
          padding: 45px 30px 60px 30px;
          line-height: 1.2;
          // padding: 3 * $pagePad #{$sideMargin} #{4 * $pagePad} #{$sideMargin};
        }
      }
    }
  }
}
@media (max-width: $breakpoint-md + 50) and (min-width: $breakpoint-sm + 1) {
  .App {
    #intro{
      h1{
        font-size: 3.5rem;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  body .App {
    .post{
      .wp-block-image{
        margin-bottom: 30px;
        .wp-caption-text,
        figcaption{
          font-size: 3.5rem;
          padding-top: 64%;
          padding-bottom: 30px;
          color: $blackText;
          // padding: 3 * $pagePad #{$sideMargin} #{4 * $pagePad} #{$sideMargin};
        }
      }
    }
  }
}