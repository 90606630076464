@import '../styles/mixins';
@import '../styles/config';

.page-persons-of-interest{
  .texture-text{
    .page-content{
      margin-bottom: 30px;
    }
  }
}

.App.poi-detail{
  #intro h1 span {
    color: $whiteText;
  }
}
.App.hp{
  .subscribe-btn{
    margin-right: 30px;
  }
}

@media(max-width: $breakpoint-md){
  .hp{
    .texture-text{
      margin-left: -30px;
      margin-right: -30px;
    }
  }
}