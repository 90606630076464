@import '../styles/config';
@import '../styles/colors';
@import '../styles/mixins';

.sidebar-wrap{
  min-width: 370px;
  width: 25%;
  margin: 2rem #{2 * $pagePad};
  #sidebar {
    background-color: $orangeBg;
    padding: 2rem #{2 * $pagePad};
    border-radius: 1rem;
    transition: 0.2s padding-top ease-out;
    .tab-line{
      padding: 0;
      transition: 0.2s all ease-out;
      border: none;
      width: 100%;
      background-color: transparent;
      outline: none !important;
      &:before{
        transition: 0.2s all ease-out;
        height: 0px;
      }
    }
    
    h2 {
      color: $whiteText;
      text-align: center;
      margin-bottom: 2rem;
      font-weight: 900;
      font-size: 3rem;
      &.big,
      &.thanks{
        font-size: 3.5rem;
      }
    }
    
    h4 {
      color: $blackText;
      text-align: center;
      margin: 2rem 0 2rem 0;
      // @extend .headline-sans;
      font-weight: 900;
      // letter-spacing: 0.8px;
      &.big{
        font-size: 2rem;
      }
    }
    
    p {
      color: $whiteText;
      text-align: center;
      font-weight: 600;
      padding: 1rem 0;
      line-height: 1.4;
      &.big{
        font-size: 2rem;
      }
    }
    small{
      font-size: 1.3rem;
      line-height: 1.2;
      display: block;
      padding-top: 15px;
      text-align: center;
      color: #523c31;
    }
    .call{
      .thanks{
        // display: none;
      }
    }
  }
  .tweet-wrap{
    label{
      padding-bottom: 10px;
      display: block;
    }
    .btn-wrap{
      display: flex;
      .btn-wrpr{
        margin: 0;
        flex: 1;
      }
      input[type=text]{
        margin-bottom: 0;
        flex: 3;
      }
    }
  }
}

@media (max-width: $breakpoint-md - 1) {
  .sidebar-wrap{
    width: 100%;
    margin: 0rem -#{$pagePad};
    min-width: calc(100vw - 3rem);
    .script{
      .common-safe-span{
        height: 200px;
        overflow-y: auto;
        display: block;
      }
    }
    h4{
      max-height: 0;
      overflow: hidden;
      transition: 0.7s all ease-out;
    }
    #sidebar{
      min-height: 70vh;
    }
    &.fixed{
      width: 100vw;
      position: fixed;
      margin: 0;
      // bottom: 0;
      transition: 0.2s bottom ease-out;
      left: 0;
      padding: 0 $pagePad;
      z-index: 5;
      h4{
        max-height: 300px;
      }
      #sidebar{
        box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.2);
        margin: 0;
        padding-top: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        .tab-line{
          padding: 1.5rem 0px 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:before{
            content: '';
            height: 4px;
            width: 30%;
            background-color: $orangeDark;
            border-radius: 2px;;
            display: block;
          }
          // &:focus:before {
          //   outline: 1px solid $whiteBg;
          // }
        }
        h4{
          display: none;
        }
      }
      &.open{
        #sidebar{
          h4{
            display: block;
          }
        }
      }
    }
  }

}
